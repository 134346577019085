/* eslint-disable */
import * as React from 'react';
import { Suspense, lazy } from 'react';
import { inject, observer } from 'mobx-react';
import AppStore, { ContentType } from './appStore';
import './appContainerStyles.scss';
import AppTopNav from './topNav/appTopNav';
import { CookieStore } from '../../../src/cookiesStore/cookieStore';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import { action, observable } from 'mobx';
import DocSplitPanel from 'pages/document/components/splitPanel/docSplitPanel';
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import Alert from '@amzn/awsui-components-react/polaris/alert';

const GRCAIAssistant = lazy(() => import('@amzn/grc-ai-assistant-frontend'));
const Breadcrumb = lazy(() => import('./breadcrumb/breadcrumb'));
const Feedback = lazy(() => import('../../../src/components/feedback/feedback'));
const DocSideNav = lazy(() => import('../../pages/document/components/sideNav/docSideNav'));

interface Props {
  appStore?: AppStore;
  children: any;
}

@inject('appStore') @observer
export class AppContainer extends React.PureComponent<Props, unknown> {


  @observable spliPanelClose: Boolean = true;


  constructor(props: Props) {
    super(props);
    const { appStore } = this.props;
    appStore!.load();
  }

  /**
   * Handles actions for clicks on the AppLayout content.
   */
  handleAppLayoutClick(): void {
    const { appStore } = this.props;

    // Hide the feedback component for outside clicks.
    if (appStore?.isFeedbackVisible) appStore.showFeedback(false, true);
  }

  /**
   * Handles actions for clicks on the topnav.
   */
  handleTopNavClick(): void {
    const { appStore } = this.props;
    // Hide the feedback component for outside clicks.
    if (appStore?.isFeedbackVisible) appStore.showFeedback(false);
  }

  // See Fixed Top Navigation section:
  // https://polaris.a2z.com/components/awsui-top-navigation/?example=with-utility-controls&tabId=api
  render(): any {
    const { children, appStore } = this.props;
    const breadcrumbItems = appStore?.breadcrumbItems || [];
    const disableContentPaddings = (appStore?.useDocumentLayout && appStore?.contentType !== ContentType.WIZARD) || appStore?.contentType === ContentType.DASHBOARD;

    const showAssistant = [
      "afarn",
      "desaiak",
      "landvogt",
      "dsevmart",
      "gerangu",
      "jingxh",
      "hlatset",
      "hadima",
      "ngoziu",
      "gajprave",
      "ridafa",
      "wuh",
      "umashanc",
      "vkhuang",
      "vishkvv",
      "gueroger",
      "mattstr"
  ].includes(appStore?.currentUserID);
    let parentFolder = '';
    const path = window.location.pathname;
  
    // Match "/folder/uuid/uuid" and extract the last UUID
    let match = path.match(/\/folder\/(?:[0-9a-fA-F-]{36}\/)*([0-9a-fA-F-]{36})/);
    if (match) {
      parentFolder = match[1];
    }

    // Match "/org/..." and extract everything after "/org/"
    match = path.match(/\/org\/(.+)/);
    if (match) {
      parentFolder = match[1];
    }
    return (
      <div className="awsui">
        {/* <AnnotationContextLayer appStore={appStore} data={appStore?.tutorialData}> */}
        <div style={{ position: 'sticky', top: 0, zIndex: 1002 }} onClick={() => this.handleTopNavClick()}>
          <AppTopNav />
        </div>
        {appStore!.loading ? <LoadingSpinner size="large" absoluteCenter /> : (
          <AppLayout
            headerVariant="high-contrast"
            disableContentPaddings={disableContentPaddings}
            className="policy--app"
            contentType={appStore!.contentType}
            content={
              (appStore?.useDocumentLayout) || appStore!.contentType === ContentType.WIZARD ? (
                <>

                  {appStore!.useStickyBreadcrumbs && (
                    <div className={'custom-home__header-sticky'}>
                      <div style={{ paddingTop: '1rem' }}>
                        {breadcrumbItems?.length > 1 && (
                          <Suspense fallback={<></>}>
                            <Breadcrumb breadcrumbItems={breadcrumbItems} isLoading={appStore!.loading} />
                          </Suspense>
                        )}
                      </div>
                    </div>
                  )}
                  {children}
                </>
              ) : (
                <section onClick={() => this.handleAppLayoutClick()} style={{ padding: appStore?.useDocumentLayout ? '1rem 4rem' : '0rem 0rem' }}>
                  {breadcrumbItems.length > 1 && appStore?.useDocumentLayout && (
                    <Suspense fallback={<></>}>
                      <Breadcrumb breadcrumbItems={breadcrumbItems} isLoading={appStore!.loading} />
                    </Suspense>
                  )}
                  {children}
                </section>
              )
            }
            breadcrumbs={
              breadcrumbItems.length > 1 && !appStore?.useDocumentLayout && (
                <Suspense fallback={<></>}>
                  <Breadcrumb breadcrumbItems={breadcrumbItems} isLoading={appStore!.loading} />
                </Suspense>
              )
            }
            headerSelector="#policy--topnav"
            toolsHide={appStore?.toolsHide ?? true}
            toolsOpen={appStore?.toolsOpen ?? false}
            navigation={
              <Suspense fallback={<></>}>
                <DocSideNav
                  sideNavStore={appStore?.docSideNavStore}
                  navItems={appStore?.sideNavItems}
                  isLoading={appStore?.sideNavItems === 'LOADING'}
                />
              </Suspense>
            }
            navigationOpen={appStore?.isSideNavOpen ?? true}
            onNavigationChange={e => appStore?.handleIsSideNavOpen(e.detail.open)}
            tools={appStore?.toolBarContent}
            navigationHide={appStore?.hideSideNav || appStore?.sideNavItems.length === 0}
            onToolsChange={() => {
              appStore?.setToolsHide();
              appStore?.setToolsOpen();
            }}
            toolsWidth={appStore?.toolBarWidth}
            navigationWidth={300}
            splitPanel={<DocSplitPanel appStore={appStore} />}
            splitPanelOpen={(appStore?.splitpanel) ? true : false}
            splitPanelPreferences={{ position: 'side' }}
            onSplitPanelToggle={action((e) => {
              appStore!.splitpanel = e?.detail?.open
            }
            )
            }
          />
        )}
        {appStore?.isFeedbackVisible && (
          <Suspense fallback={<></>}>
            <Feedback appStore={appStore} defaultOpen primary position="top-right" />
          </Suspense>
        )}
        <CookieStore ref={appStore?.cookiesStore} didMount={() => appStore?.checkCookiesStore()} />
        {/* </AnnotationContextLayer> */}

        {showAssistant && <Suspense fallback={<></>}>
        <GRCAIAssistant apps="policy" searchFolder={parentFolder} />
        </Suspense>}
      </div>
    );
  }
}

export default AppContainer;
