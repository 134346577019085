import React from 'react';
import * as ReactDOM from 'react-dom';
import AppStore from './container/app/appStore';
import { Provider } from 'mobx-react';
import { routingStore } from './routes';
import { configure } from 'mobx';
import Amplify, { Auth, Hub } from 'aws-amplify';
import appConfig from 'api/appConfig';
import { AppSyncClient } from './api/auth/appSyncClient';
import { populateUserGroups } from 'api/graphql/mutation';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import App from 'app';
// import '@amzn/awsui-global-styles/polaris.css';
import '@amzn/arte-frontend/dist/arte.css';

// Built-in internationalization
// Ref: https://refresh.cloudscape.aws.dev/get-started/dev-guides/internationalization/
import { I18nProvider } from '@amzn/awsui-components-react/polaris/i18n';
import messages from '@amzn/awsui-components-react/polaris/i18n/messages/all.all';
import AppTopNav from 'container/app/topNav/appTopNav';

Amplify.configure(appConfig.AmplifyConfig);
configure({ enforceActions: 'observed' });

Auth.currentSession()
  .then(async session => {
    const appsync = new AppSyncClient(session);
    const appStore = new AppStore(appsync, routingStore, session);
    try {
      await appStore.api.mutate(populateUserGroups, {});
    } catch {
      // do nothing here - If user group retrival fails, do no refresh the apge again
    }

    // Prevent redirecting to sign in on render issues
    try {
      ReactDOM.render(
        <Provider appStore={appStore}>
          <I18nProvider messages={[messages]}>
            <App />
          </I18nProvider>
        </Provider>, document.getElementById('root'),
      );
    } catch (error) {
      console.error('Error rendering React application:', error); // eslint-disable-line no-console
    }
  })
  .catch(() => {
    // Only sign-in if not already signed in.
    Auth.federatedSignIn({ customProvider: 'AmazonFederate', customState: window.location.href });
  });

Hub.listen('auth', ({ payload: { event, data } }) => {
  switch (event) {
    case 'customOAuthState':
      window.location.replace(data);
      break;
  }
});

const appStore = {} as AppStore;
const skeleton =
  <div className="awsui">
    <Provider appStore={appStore}>
      <AppTopNav isPlaceholder />
      <LoadingSpinner size="large" absoluteCenter />
    </Provider>
  </div>;

ReactDOM.render(
  skeleton, document.getElementById('root'),
);
