
/* istanbul ignore file */
import gql from 'graphql-tag';
import { Auth } from 'aws-amplify';
import { GraphQLAPI } from '@aws-amplify/api-graphql';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export class AppSyncClient {
  private session: CognitoUserSession;

  constructor(session: CognitoUserSession) {
    this.session = session;
  }

  public async query<T>(query: string, variables: any, noCache = true): Promise<any> {
    try {
      const p = await this.ensureAuthenticatedCall<T>({
        query: gql(query),
        variables,
        fetchPolicy: noCache ? 'no-cache' : 'cache-first',
      });
      return p;
    } catch (e) {
      throw e;
    }
  }

  public async mutate<T>(query: string, variables: any): Promise<any> {
    try {
      const p = await this.ensureAuthenticatedCall<T>({
        query: gql(query),
        variables,
      });
      return p;
    } catch (e) {
      throw e;
    }
  }

  // Have to pass in the method and options instead of doing normal Promise flow
  // Because refreshSession method takes a callback and returns void instead of returning a Promise.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private async ensureAuthenticatedCall<T>(options: any, additionalHeaders?: { [key: string]: string }): Promise<any> {
    return new Promise(async resolve => {
      if (!this.session.isValid()) {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        cognitoUser.refreshSession(this.session.getRefreshToken(), (err, session) => {
          this.session = session;
          resolve(GraphQLAPI.graphql(options, { Authorization: this.session.getIdToken().getJwtToken(), ...additionalHeaders }));
        });
      } else {
        resolve(GraphQLAPI.graphql(options, { Authorization: this.session.getIdToken().getJwtToken(), ...additionalHeaders }));
      }
    });
  }
}
